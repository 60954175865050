<template>
    <div class="content">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-12 text-center mb-4">
              <div class="fs-1">Варианты подписок на <small>GET</small>Wealthy </div>
              <h3 class="fs-2 fs-md-3">Выберите наиболее подходящий для Вас вариант!</h3>
            </div>
            <div class="col-12 col-lg-8">
              <div class="row">
                <div class="col-12 col-md mb-3">
                  <div class="border rounded-soft overflow-hidden mb-3 mb-md-0">
                    <div class="d-flex justify-content-between align-items-center p-4">
                      <div>
                        <h3 class="font-weight-light fs-5 mb-0 text-primary">Месяц</h3>
                        <h2 class="font-weight-light mt-0 text-primary">
                          <sup class="fs-1">&#8381;</sup>
                          <span class="fs-3">345</span>
                          <span class="fs--2 mt-1">/ месяц</span></h2>
                      </div>
                      <div class="pr-3"><img src="@/assets/img/illustrations/paper-plane.svg" width="70" alt=""></div>
                    </div>
                    <div class="p-4 bg-light">
                      <ul class="list-unstyled">
                        <li class="border-bottom py-2"> 
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Статус пользователя - <strong>Базовый</strong>
                        </li>
                        <li class="border-bottom py-2"> 
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Помесячная оплата 
                        </li>
                        <li class="py-2 border-bottom">
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Поддержка 24/7 
                        </li>
                        <li class="py-2 border-bottom">
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Доступ к широму списку функций
                        </li>
                      </ul>
                      <form name="TinkoffPayForm" onsubmit="pay(this); return false;">
                        <input class="tinkoffPayRow" type="hidden" name="terminalkey" value="1568821851852">
                        <input class="tinkoffPayRow" type="hidden" name="frame" value="false">
                        <input class="tinkoffPayRow" type="hidden" name="language" value="ru">
                          <input class="tinkoffPayRow" type="hidden" name="amount" value="345">
                          <input class="tinkoffPayRow" type="hidden" name="order" :value="orderId">
                          <input class="tinkoffPayRow" type="hidden" name="description" value="Basic">
                          <input class="tinkoffPayRow" type="hidden" name="name" :value="userInfo.firstName">
                          <input class="tinkoffPayRow" type="hidden" name="email" :value="userInfo.email">
                          <input class="tinkoffPayRow" type="hidden" name="phone" :value="userInfo.phone">
                          <input class="tinkoffPayRow" type="hidden" name="receipt" :value="receiptBasic">
                          <input 
                            class="btn btn-outline-primary btn-block"
                            @click="handlePurchase('Basic', 345, 1)" 
                            type="submit" 
                            value="Приобрести">
                      </form>
                      
                      <!-- <router-link 
                        class="btn btn-outline-primary btn-block" 
                        to="/checkout?plan=basic">
                        Приобрести
                      </router-link> -->
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md mb-3">
                  <div class="border rounded-soft overflow-hidden">
                    <div class="d-flex justify-content-between align-items-center p-4">
                      <div>
                        <h3 class="font-weight-light text-primary fs-5 mb-0">Квартал</h3>
                        <h2 class="font-weight-light text-primary mt-0">
                          <sup class="fs-1">&#8381;</sup>
                          <span class="fs-3">295</span><span class="fs--2 mt-1">/ месяц</span></h2>
                      </div>
                      <div class="pr-3">
                        <img src="@/assets/img/illustrations/plane.svg" width="70" alt="">
                      </div>
                    </div>
                    <div class="p-4 bg-light">
                      <ul class="list-unstyled">
                        <li class="border-bottom py-2">
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Статус пользоваталя - <strong class="text-warning">Золотой</strong>
                        </li>
                        <li class="border-bottom py-2"> 
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Экономия <span class="badge badge-pill badge-success">15%</span>
                        </li>
                        <li class="py-2 border-bottom">
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Поддержка 24/7 
                        </li>
                        <li class="py-2 border-bottom">
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Доступ к широму списку функций
                        </li>
                      </ul>
                      <form name="TinkoffPayForm" onsubmit="pay(this); return false;">
                        <input class="tinkoffPayRow" type="hidden" name="terminalkey" value="1568821851852">
                        <input class="tinkoffPayRow" type="hidden" name="frame" value="false">
                        <input class="tinkoffPayRow" type="hidden" name="language" value="ru">
                          <input class="tinkoffPayRow" type="hidden" name="amount" value="885">
                          <input class="tinkoffPayRow" type="hidden" name="order" :value="orderId">
                          <input class="tinkoffPayRow" type="hidden" name="description" value="Gold">
                          <input class="tinkoffPayRow" type="hidden" name="name" :value="userInfo.firstName">
                          <input class="tinkoffPayRow" type="hidden" name="email" :value="userInfo.email">
                          <input class="tinkoffPayRow" type="hidden" name="phone" :value="userInfo.phone">
                          <input class="tinkoffPayRow" type="hidden" name="receipt" :value="receiptGold">
                          <input 
                            class="btn btn-outline-primary btn-block"
                            @click="handlePurchase('Gold', 885, 3)" 
                            type="submit" 
                            value="Приобрести">
                      </form>  
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md">
                  <div class="border rounded-soft overflow-hidden">
                    <div class="d-flex justify-content-between align-items-center p-4">
                      <div>
                        <h3 class="font-weight-light text-primary fs-5 mb-0">Год</h3>
                        <div class="row">
                          <div class="col-12 pr-0">
                            <h2 class="font-weight-light text-primary mt-0">
                              <sup class="fs-1">&#8381;</sup>
                              <span class="fs-3">225</span><span class="fs--2 mt-1">/ месяц</span>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div class="pr-3">
                        <img src="@/assets/img/illustrations/startup.svg" width="70" alt="">
                      </div>
                    </div>
                    <div class="p-4 bg-light">
                      <ul class="list-unstyled">
                        <li class="border-bottom py-2">
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Статус пользователя - <strong class="text-primary">Премиум</strong>
                        </li>
                        <li class="border-bottom py-2"> 
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Экономия <span class="badge badge-pill badge-success">35%</span>
                        </li>
                        <li class="py-2 border-bottom">
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Поддержка 24/7
                        </li>
                        <li class="py-2 border-bottom">
                          <span class="fas fa-check text-primary" data-fa-transform="shrink-2"></span> 
                          Доступ к широму списку функций
                        </li>
                      </ul>
                      <form name="TinkoffPayForm" onsubmit="pay(this); return false;">
                        <input class="tinkoffPayRow" type="hidden" name="terminalkey" value="1568821851852">
                        <input class="tinkoffPayRow" type="hidden" name="frame" value="false">
                        <input class="tinkoffPayRow" type="hidden" name="language" value="ru">
                          <input class="tinkoffPayRow" type="hidden" name="amount" value="2700">
                          <input class="tinkoffPayRow" type="hidden" name="order" :value="orderId">
                          <input class="tinkoffPayRow" type="hidden" name="description" value="Premium">
                          <input class="tinkoffPayRow" type="hidden" name="name" :value="userInfo.firstName">
                          <input class="tinkoffPayRow" type="hidden" name="email" :value="userInfo.email">
                          <input class="tinkoffPayRow" type="hidden" name="phone" :value="userInfo.phone">
                          <input class="tinkoffPayRow" type="hidden" name="receipt" :value="receiptPremium">
                          <input 
                            class="btn btn-outline-primary btn-block"
                            @click="handlePurchase('Premium', 2700, 12)" 
                            type="submit" 
                            value="Приобрести">
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <p class="text-500 fs--2">* - перед покупкокой, просьба ознакомиться с 
                <a v-b-modal.userAgreementModal href="#">пользовательским соглашением</a> 
                <user-agreement-modal />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h5 class="mb-0">Часто задаваемые вопросы</h5>
        </div>
        <div class="card-body bg-light">
          <div class="row">
            <div class="col-lg-6">
              <h5 class="fs-0">Как работаю подписки?</h5>
              <p class="fs--1">
                Для работы с системой <small>GET</small><strong>Wealthy</strong> необходима подписка. Она обеспечивает доступ к закрытым функциям, 
                в том числе сохранения транзакций в базе данных. 
              </p>
              <h5 class="fs-0">Возможен ли доступ к системе без подписки?</h5>
              <p class="fs--1">
                Да. Если Вы впервые в сервисе и еще не оформляли подписку, то Вам будет доступна возможность ознакомиться с функционалом системы.
                Вы сможете добавлять как свои данные, так и загрузить демо-сет. Однако будьте внимательны, все изменения будут потеряны по завершению сеанса.</p>
              <h5 class="fs-0">Если я оформил подписку и не успел ее продлить, доступ к моим данным будет закрыт?</h5>
              <p class="fs--1">
                Нет, Вам по прежнему будут доступны Ваши записи и аналитика по ним. Однако, все вновь вносимые данные будут видны только в рамках текущего сеанса и 
                потеряны при его завершении.</p>
            </div>
            <div class="col-lg-6">
              <h5 class="fs-0">Для чего такое количество планов пописок?</h5>
              <p class="fs--1">
                  Мы стремимся ответить интересам большинства наших пользователей и предоставляем им возможность гибкого выбора.
              </p>
                  <h5 class="fs-0">Насколько безопасно внесение данных в <strong><small>GET</small>Wealthy</strong>?</h5>
              <p class="fs--1 mb-lg-0">
                Защита Ваших данных один из наших приоритетов. Данные проходят сквозное шифрование и храняться в зашифрованном виде. 
                Ежедневно делаются резервные копии баз данных.
              </p>
            </div>
          </div>
        </div>
        <div class="card-footer">

          <div class="row ml-4">
            <div class="col-lg-6">
              <h6 class="text-uppercase text-500 mb-3">Контакты:</h6>
              <p class="text-400 fs--2">
                <strong>ИП "Лавренюк Д.И."</strong> (ИНН: 250206796009)
                <br>
                692751, РОССИЯ, ПРИМОРСКИЙ КРАЙ, г.АРТЕМ, ул. НОРИЛЬСКАЯ, ДОМ 14, оф. 31
                <br><br>
                Тел: 8 (495) 660-08-70
                <br>
                Email: <a href="mailto: lavrenyk@gmail.com">lavrenyk@gmail.com </a>
              </p>
            </div>
            <div class="col-lg-5 text-left ">
              <h6 class="text-uppercase text-500">Остались вопросы?</h6>
              <a class="btn btn-sm btn-outline-primary" href="mailto:lavrenyk@gmail.com" >Задайти их нам</a>
            </div>
          </div>
        </div>
      </div>

    </div>
    
</template>

<script>
import firebase from 'firebase'

import UserAgreementModal from '../components/app/modals/UserAgreementModal'

export default {
  metaInfo() {
    return {
      title: this.$title('Подписки')
    }
  },
  async mounted() {
    let tinkoffPay = document.createElement('script');
    let googlePay = document.createElement('script');

    tinkoffPay.setAttribute(
      'src', 
      'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js');
    document.head.appendChild(tinkoffPay)
    googlePay.setAttribute(
      'src', 
      'https://pay.google.com/gp/p/js/pay.js')
    // let googlePayLoaded = await document.head.appendChild(googlePay)
    // this.onGooglePayLoaded()
  },
  components: {
    UserAgreementModal
  },
  computed: {
    receiptBasic() {
      let reciptData = {
        "Email": this.userInfo.email,
        "Phone": this.userInfo.phone,
        "EmailCompany": "payments@rsystems.io",
        "Taxation": "usn_income_outcome",
        "Items": [{
            "Name": "Подписка по тарифу - Базовый",
            "Price": '34500',
            "Quantity": '1.00',
            "Amount": '34500',
            "PaymentMethod": "full_prepayment",
            "PaymentObject": "intellectual_activity",
            "Tax": "none" 
          }]
        }
      return JSON.stringify(reciptData)
    },
    receiptGold() {
      let reciptData = {
        "Email": this.userInfo.email,
        "Phone": this.userInfo.phone,
        "EmailCompany": "payments@rsystems.io",
        "Taxation": "usn_income_outcome",
        "Items": [{
            "Name": "Подписка по тарифу - Золотой",
            "Price": 29500,
            "Quantity": 3.00,
            "Amount": 88500,
            "PaymentMethod": "full_prepayment",
            "PaymentObject": "intellectual_activity",
            "Tax": "none" 
          }]
        }
      return JSON.stringify(reciptData)
    },
    receiptPremium() {
      let reciptData = {
        "Email": this.userInfo.email,
        "Phone": this.userInfo.phone,
        "EmailCompany": "payments@rsystems.io",
        "Taxation": "usn_income_outcome",
        "Items": [{
            "Name": "Подписка по тарифу - Премиум",
            "Price": 22500,
            "Quantity": 12.00,
            "Amount": 270000,
            "PaymentMethod": "full_prepayment",
            "PaymentObject": "intellectual_activity",
            "Tax": "none" 
          }]
        }
      return JSON.stringify(reciptData)
    },
    currentUser() {
      let currentUser = firebase.auth().currentUser
      return currentUser
    },
    userInfo() {
      var userInfo = this.$store.getters.userInfo || null
      if (userInfo) {
        return userInfo
      } else {
        return {firstName: '-', lastName: '-', phone: '', email: ''}
      }
    },
    orderId() {
      var userInfo = this.$store.getters.userInfo || null
      if (userInfo) {
        let payment = userInfo.payments || {}
        return `${userInfo.id}_${Object.values(payment).length + 1 || 1}`
      } else {
        return null
      }
    }
  },
  methods: {
    handlePurchase(subscriptionType, amount, months) {
      if (this.orderId == null) {
        return
      }

      let paymentData = {
        orderId: this.orderId,
        date: Date.now(),
        subscriptionType: subscriptionType,
        amount: amount, 
        tillDate: Date.now() + (2629800000 * months),
      }

      this.$store.dispatch('addUserPayment', paymentData)
    }
    // onGooglePayLoaded() {
    //   var allowedPaymentMethods = ['CARD', 'TOKENIZED_CARD']
    //   var allowedCardNetworks = ['MASTERCARD', 'VISA']
    //   var tokenizationParameters = {
    //     tokenizationType: 'PAYMENT_GATEWAY',
    //     parameters: {
    //       'gateway': 'example', // ***заменить на название реального шлюза, например,
    //       'gatewayMerchantId': 'abc123' // ***заменить на идентификатор, выданный ШЛЮЗОМ
    //     }
    //   }
    // }
  }
}
</script>

<style>

</style>